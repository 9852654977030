var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-form-container" },
    [
      _c("div", { staticClass: "mb-2" }, [
        _c(
          "p",
          {
            staticClass: "title-font font-weight-bold primary_font--text mb-2"
          },
          [_vm._v(_vm._s(_vm.$t("forms.RegisterForm.welcome.sign_in")))]
        ),
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.$t("forms.RegisterForm.welcome.welcome", {
                program_name: _vm.program_name
              })
            )
          )
        ])
      ]),
      _vm.validationError
        ? _c("v-alert", { attrs: { type: "error", text: "", outlined: "" } }, [
            _vm._v(
              " " + _vm._s(_vm.$t("views.RegisterView.validation_error")) + " "
            )
          ])
        : _vm._e(),
      _vm.birthdateError
        ? _c("v-alert", { attrs: { type: "error", text: "", outlined: "" } }, [
            _vm._v(" " + _vm._s(_vm.birthdateErrorText) + " ")
          ])
        : _vm._e(),
      _vm.documentError
        ? _c("v-alert", { attrs: { type: "error", text: "", outlined: "" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("views.RegisterView.used_document_error")) +
                " "
            )
          ])
        : _vm._e(),
      _vm.emailError
        ? _c("v-alert", { attrs: { type: "error", text: "", outlined: "" } }, [
            _vm._v(" " + _vm._s(_vm.$t("views.RegisterView.email_error")) + " ")
          ])
        : _vm._e(),
      _vm.optInError
        ? _c("v-alert", { attrs: { type: "error", text: "", outlined: "" } }, [
            _vm._v(
              " " + _vm._s(_vm.$t("views.RegisterView.opt_in_error")) + " "
            )
          ])
        : _vm._e(),
      _c("RegisterForm", {
        ref: "form",
        attrs: {
          loading: _vm.loading,
          validationError: _vm.validationError,
          birthdateError: _vm.birthdateError,
          documentError: _vm.documentError,
          emailError: _vm.emailError,
          optInError: _vm.optInError,
          isFormValid: _vm.isFormValid,
          useCNPJ: _vm.useCNPJ
        },
        on: {
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:validationError": function($event) {
            _vm.validationError = $event
          },
          "update:validation-error": function($event) {
            _vm.validationError = $event
          },
          "update:birthdateError": function($event) {
            _vm.birthdateError = $event
          },
          "update:birthdate-error": function($event) {
            _vm.birthdateError = $event
          },
          "update:documentError": function($event) {
            _vm.documentError = $event
          },
          "update:document-error": function($event) {
            _vm.documentError = $event
          },
          "update:emailError": function($event) {
            _vm.emailError = $event
          },
          "update:email-error": function($event) {
            _vm.emailError = $event
          },
          "update:optInError": function($event) {
            _vm.optInError = $event
          },
          "update:opt-in-error": function($event) {
            _vm.optInError = $event
          },
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          },
          "document-changed": _vm.changeDocument
        },
        scopedSlots: _vm._u([
          {
            key: "submit",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "my-3",
                    attrs: {
                      block: "",
                      elevation: "0",
                      height: "42px",
                      disabled: !_vm.isFormValid,
                      loading: _vm.loading,
                      type: "submit",
                      color: "primary"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.register")))]
                ),
                _c(
                  "div",
                  { staticClass: "mt-6 d-flex justify-center" },
                  [
                    _c("span", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.$t("views.LoginView.has_register")))
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "hyper-link",
                        attrs: { to: { name: "auth.email_login" } }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("views.LoginView.sign_in")) + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }