import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=17c846a0&scoped=true&"
import script from "./Register.vue?vue&type=script&lang=js&"
export * from "./Register.vue?vue&type=script&lang=js&"
import style0 from "./Register.vue?vue&type=style&index=0&id=17c846a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17c846a0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VAlert,VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17c846a0')) {
      api.createRecord('17c846a0', component.options)
    } else {
      api.reload('17c846a0', component.options)
    }
    module.hot.accept("./Register.vue?vue&type=template&id=17c846a0&scoped=true&", function () {
      api.rerender('17c846a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/auth/Register.vue"
export default component.exports