var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          },
          model: {
            value: _vm.isFormValid,
            callback: function($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid"
          }
        },
        [
          _c("cp-text-field", {
            attrs: {
              autofocus: "",
              label: _vm.$t("forms.RegisterForm.name.label"),
              hint: _vm.$t("forms.RegisterForm.name.hint"),
              placeholder: _vm.$t("forms.RegisterForm.name.placeholder"),
              disabled: _vm.loading,
              required: "",
              rules: _vm.rules.name
            },
            model: {
              value: _vm.formData.name,
              callback: function($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name"
            }
          }),
          _vm.companySettings.is_age_restriction_enabled
            ? _c("cp-date-field", {
                ref: "birthdate",
                staticClass: "mt-n1",
                attrs: {
                  label: _vm.$t("forms.CompleteForm.birthdate.label"),
                  hint: _vm.$t("forms.RegisterForm.email.hint"),
                  placeholder: _vm.$t(
                    "forms.CompleteForm.birthdate.placeholder"
                  ),
                  disabled: _vm.loading,
                  required: "",
                  reactive: "",
                  birthday: "",
                  rules: _vm.rules.birthdate
                },
                model: {
                  value: _vm.formData.birthdate,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "birthdate", $$v)
                  },
                  expression: "formData.birthdate"
                }
              })
            : _vm._e(),
          _c("cp-text-field", {
            staticClass: "mt-n1",
            attrs: {
              type: "email",
              label: _vm.$t("forms.RegisterForm.email.label"),
              hint: _vm.$t("forms.RegisterForm.email.hint"),
              placeholder: _vm.$t("forms.RegisterForm.email.placeholder"),
              disabled: _vm.loading,
              required: "",
              rules: _vm.rules.email
            },
            model: {
              value: _vm.formData.email,
              callback: function($$v) {
                _vm.$set(_vm.formData, "email", $$v)
              },
              expression: "formData.email"
            }
          }),
          _c("cp-document-field", {
            ref: "document_field",
            attrs: {
              label: _vm.$t(
                "forms.RegisterForm.document.label." +
                  (_vm.computedUseCNPJ ? "cnpj" : "cpf")
              ),
              placeholder: _vm.$t(
                "forms.RegisterForm.document.placeholder." +
                  (_vm.computedUseCNPJ ? "cnpj" : "cpf")
              ),
              required: "",
              type: "text",
              useCNPJ: _vm.computedUseCNPJ,
              rules: _vm.rules.document
            },
            on: {
              "update:useCNPJ": function($event) {
                _vm.computedUseCNPJ = $event
              },
              "update:use-c-n-p-j": function($event) {
                _vm.computedUseCNPJ = $event
              }
            },
            model: {
              value: _vm.formData.document,
              callback: function($$v) {
                _vm.$set(_vm.formData, "document", $$v)
              },
              expression: "formData.document"
            }
          }),
          _c("cp-password-field", {
            staticClass: "mt-n1",
            attrs: {
              type: "password",
              label: _vm.$t("forms.RegisterForm.password.label"),
              hint: _vm.$t("forms.RegisterForm.password.hint"),
              placeholder: _vm.$t("forms.RegisterForm.password.placeholder"),
              disabled: _vm.loading,
              required: "",
              rules: _vm.rules.password
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.submit($event)
              }
            },
            model: {
              value: _vm.formData.password,
              callback: function($$v) {
                _vm.$set(_vm.formData, "password", $$v)
              },
              expression: "formData.password"
            }
          }),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex inline align-center contain-hyper-link"
                  },
                  [
                    _c("v-checkbox", {
                      staticClass: "ma-0",
                      attrs: { "hide-details": "" },
                      model: {
                        value: _vm.formData.opt_in,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "opt_in", $$v)
                        },
                        expression: "formData.opt_in"
                      }
                    }),
                    _vm.customTermsHtml
                      ? _c("p", {
                          staticClass: "ma-0 caption text-justify",
                          domProps: { innerHTML: _vm._s(_vm.customTermsHtml) }
                        })
                      : _c("i18n", {
                          staticClass: "ma-0 caption text-justify",
                          attrs: {
                            path: "forms.RegisterForm.opt_in.label",
                            tag: "p"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "terms",
                              fn: function() {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "caption",
                                      class: _vm.isLightTheme
                                        ? "blue--text"
                                        : "primary--text",
                                      attrs: {
                                        href: _vm.termsUrl,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "terms-privacy" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.RegisterForm.opt_in.terms"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "privacy",
                              fn: function() {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "caption",
                                      class: _vm.isLightTheme
                                        ? "blue--text"
                                        : "primary--text",
                                      attrs: {
                                        href: _vm.privacyPolicyUrl,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "forms.RegisterForm.opt_in.privacy_policy"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex inline align-center" },
                  [
                    _c("v-checkbox", {
                      staticClass: "ma-0",
                      attrs: { "hide-details": "" },
                      model: {
                        value: _vm.formData.opt_in_marketing,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "opt_in_marketing", $$v)
                        },
                        expression: "formData.opt_in_marketing"
                      }
                    }),
                    _c("p", { staticClass: "ma-0 caption text-justify" }, [
                      _vm._v(" " + _vm._s(_vm.optInMarketingLabel) + " ")
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._t("default"),
          _vm._t("submit")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }