<template>
    <div>
        <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValid">
            <cp-text-field
                autofocus
                v-model="formData.name"
                :label="$t('forms.RegisterForm.name.label')"
                :hint="$t('forms.RegisterForm.name.hint')"
                :placeholder="$t('forms.RegisterForm.name.placeholder')"
                :disabled="loading"
                required
                :rules="rules.name"
            />

            <cp-date-field
                class="mt-n1"
                v-if="companySettings.is_age_restriction_enabled"
                v-model="formData.birthdate"
                ref="birthdate"
                :label="$t('forms.CompleteForm.birthdate.label')"
                :hint="$t('forms.RegisterForm.email.hint')"
                :placeholder="$t('forms.CompleteForm.birthdate.placeholder')"
                :disabled="loading"
                required reactive birthday
                :rules="rules.birthdate"
            />

            <cp-text-field
                class="mt-n1"
                v-model="formData.email"
                type="email"
                :label="$t('forms.RegisterForm.email.label')"
                :hint="$t('forms.RegisterForm.email.hint')"
                :placeholder="$t('forms.RegisterForm.email.placeholder')"
                :disabled="loading"
                required
                :rules="rules.email"
            />

            <cp-document-field
                ref="document_field"
                :label="$t(`forms.RegisterForm.document.label.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                :placeholder="$t(`forms.RegisterForm.document.placeholder.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                required type="text"
                :useCNPJ.sync="computedUseCNPJ"
                v-model="formData.document"
                :rules="rules.document"
            />

            <cp-password-field
                class="mt-n1"
                v-model="formData.password"
                type="password"
                :label="$t('forms.RegisterForm.password.label')"
                :hint="$t('forms.RegisterForm.password.hint')"
                :placeholder="$t('forms.RegisterForm.password.placeholder')"
                @keyup.enter="submit"
                :disabled="loading"
                required
                :rules="rules.password"
            />

            <!-- <cp-password-field
                class="mt-n1"
                v-model="formData.password_confirmation"
                type="password"
                :label="$t('forms.RegisterForm.password_confirmation.label')"
                :hint="$t('forms.RegisterForm.password_confirmation.hint')"
                :placeholder="$t('forms.RegisterForm.password_confirmation.placeholder')"
                @keyup.enter="submit"
                :disabled="loading"
                required
                :rules="rules.password_confirmation"
            /> -->

            <v-row>
                <v-col cols="12"  class="py-0">
                    <div class="d-flex inline align-center contain-hyper-link">
                        <v-checkbox
                            v-model="formData.opt_in"
                            hide-details
                            class="ma-0"
                        />
                        <p class="ma-0 caption text-justify" v-if="customTermsHtml" v-html="customTermsHtml">
                        </p>
                        <i18n v-else path="forms.RegisterForm.opt_in.label" tag="p" class="ma-0 caption text-justify">
                            <template #terms>
                                <a :href="termsUrl" target="_blank"  class="caption" :class="isLightTheme ? 'blue--text': 'primary--text'"><span class="terms-privacy">{{ $t('forms.RegisterForm.opt_in.terms') }}</span></a>
                            </template>

                            <template #privacy>
                                <a :href="privacyPolicyUrl" target="_blank" class="caption" :class="isLightTheme ? 'blue--text': 'primary--text'">{{ $t('forms.RegisterForm.opt_in.privacy_policy') }}</a>
                            </template>
                        </i18n>
                    </div>
                </v-col>

                <v-col cols="12">
                    <div class="d-flex inline align-center">
                        <v-checkbox
                            v-model="formData.opt_in_marketing"
                            hide-details
                            class="ma-0"
                        />
                        <p class="ma-0 caption text-justify">
                            {{ optInMarketingLabel }}
                        </p>
                    </div>
                </v-col>
            </v-row>
                
            <!-- Slot para campos adicionais -->
            <slot></slot>

            <!-- Slot para botão de submit -->
            <slot name="submit"></slot>

        </v-form>
    </div>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import RateColor            from '@/shared/mixins/RateColor'
import SimpleCustomer       from '@/models/SimpleCustomer'
import { mapGetters }       from 'vuex'
import { required, length, email, birthday, cpf, cnpj } from '@/utils/validation'
import sanitizeHtml         from 'sanitize-html'

export default {
    name: 'RegisterForm',
    mixins: [ HasErrorHandlerMixin, RateColor ],
    props: {
        disabled: Boolean,
        useCNPJ : Boolean,
    },
    data: vm => ({
        formData: {
            name                 : '',
            email                : '',
            password             : '',
            // password_confirmation: '',
            opt_in               : false,
            opt_in_marketing     : false,
            document             : '',
        },
        loading        : false,
        isFormValid    : true,

        // Errors 
        // passwordError  : false,
        validationError: false,
        documentError  : false,
        emailError     : false,
        optInError     : false,
        birthdateError : false,
    }),
    methods: {
        async submit() {
            // this.passwordError   = false
            this.validationError = false
            this.documentError   = false
            this.emailError      = false
            this.optInError      = false
            this.birthdateError  = false

            let isValid = this.$refs.form.validate()

            if (!isValid || !this.isFormValid)
                return

            // [TODO][FID-648] Corrigir componente InputDate para retornar valores SEM MÁSCARA
            // [TODO-L][FID-114] internacionalizar
            if (this.companySettings.applyAgeRestrictionRuleTo(this.$moment(this.formData.birthdate, 'DD/MM/YYYY')) !== true) {
                this.birthdateError = true
                return
            }

            // if (this.formData.password != this.formData.password_confirmation) {
            //     this.passwordError = true
            //     return
            // }

            if (!this.formData.opt_in) {
                this.optInError = true
                return
            }

            // [TODO][FID-648] Corrigir componente InputDate para retornar valores SEM MÁSCARA
            // [TODO-L][FID-114] internacionalizar
            let customer = new SimpleCustomer(this.formData)
            customer.formatToBack()

            this.loading = true
            let res = await customer.createViaEmail()
                .catch(this.preErrorHandler)
            this.loading = false
        },
        preErrorHandler(e) {
            if (e && e.response && e.response.status == 422) {
                if (e.response.data && e.response.data.code == 'used_email') {
                    this.emailError = true
                    return
                }

                if (e.response.data && e.response.data.code == 'used_document') {
                    this.documentError = true
                    return
                }

                this.validationError = true
                return
            }

            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            json            : 'company/json',
            companySettings : 'company/settings',
            companyName     : 'company/name',
        }),

        customTermsHtml() {
            const custom_terms = this.$lodash.get(this.json, 'legal.custom_terms_html', undefined)
            return custom_terms ? sanitizeHtml(custom_terms, { 
                allowedTags: [ 'p', 'a' ],
                allowedAttributes: {
                    'a': [ 'href', 'target', 'rel' ]
                },
            }) : undefined
        },
        termsUrl() {
            return this.$lodash.get(this.json, 'legal.terms_url', undefined)
        },
        privacyPolicyUrl() {
            return this.$lodash.get(this.json, 'legal.privacy_policy_url', undefined)
        },
        optInMarketingLabel() {
            return this.$lodash.get(this.json, 'frontend.register.opt_in_marketing.label', this.$t('forms.RegisterForm.opt_in_marketing.label', { companyName: this.companyName }))
        },

        computedUseCNPJ: {
            get() {
                return this.useCNPJ
            },
            set(value) {
                this.$emit('document-changed', value)
            },
        },
        rules() {
            return {
                name     : [required.and(length(2,191))],
                email    : [required.and(email).and(length(2,191))],
                birthdate: [required.and(birthday)],
                password : [required.and(length(6, 30))],
                document : [required.and(this.computedUseCNPJ ? cnpj : cpf)],
                // password_confirmation: [required.and(length(6, 30))],
            }
        },
    },
    watch: {
        // passwordError(v) {
        //     this.$emit('update:passwordError', v)
        // },
        isFormValid(v) {
            this.$emit('update:isFormValid', v)
        },
        loading(v) {
            this.$emit('update:loading', v)
        },
        validationError(v) {
            this.$emit('update:validationError', v)
        },
        birthdateError(v) {
            this.$emit('update:birthdateError', v)
        },
        documentError(v) {
            this.$emit('update:documentError', v)
        },
        emailError(v) {
            this.$emit('update:emailError', v)
        },
        optInError(v) {
            this.$emit('update:optInError', v)
        },
    },
}
</script>

<style lang="scss">
// Atenção: não pode ser scoped pois o <p></p> não vem do vue e sim de um texto
// do backend, portanto não tem anotação data-v-### de scoped
.contain-hyper-link p {
    margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
.contain-hyper-link a {
    text-decoration: none;
}

p {
    line-height: 1.075rem !important;
}

.v-input--selection-controls {
    padding: 0;
}
</style>
