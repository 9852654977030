<template>
    <div class="register-form-container">
        <div class="mb-2">
            <p class="title-font font-weight-bold primary_font--text mb-2">{{ $t('forms.RegisterForm.welcome.sign_in') }}</p>
            <p>{{ $t('forms.RegisterForm.welcome.welcome',{ program_name }) }}</p>
        </div>

        <v-alert type="error" text outlined v-if="validationError">
            {{ $t('views.RegisterView.validation_error') }}
        </v-alert>

        <v-alert type="error" text outlined v-if="birthdateError">
            {{ birthdateErrorText }}
        </v-alert>

        <v-alert type="error" text outlined v-if="documentError">
            {{ $t('views.RegisterView.used_document_error') }}
        </v-alert>

        <!-- <v-alert type="error" text outlined v-if="passwordError">
            {{ $t('views.RegisterView.password_error') }}
        </v-alert> -->

        <v-alert type="error" text outlined v-if="emailError">
            {{ $t('views.RegisterView.email_error') }}
        </v-alert>

        <v-alert type="error" text outlined v-if="optInError">
            {{ $t('views.RegisterView.opt_in_error') }}
        </v-alert>

        <RegisterForm ref="form" :loading.sync="loading"
            :validationError.sync="validationError"
            :birthdateError.sync="birthdateError"
            :documentError.sync="documentError"
            :emailError.sync="emailError"
            :optInError.sync="optInError"
            :isFormValid.sync="isFormValid"
            @document-changed="changeDocument"
            :useCNPJ="useCNPJ"
        >
            <!-- :passwordError.sync="passwordError" -->
            <template #submit>
                <v-btn block elevation="0" height="42px" :disabled="!isFormValid" :loading="loading" type="submit" color="primary" class="my-3">{{ $t('actions.register') }}</v-btn>
                <!-- <v-btn block :disabled="loading" :to="{ name: 'login' }" text>{{ $t('actions.cancel') }}</v-btn> -->
                <div class="mt-6 d-flex justify-center">
                    <span class="mr-1">{{ $t('views.LoginView.has_register')}}</span>
                    <router-link class="hyper-link" :to="{ name: 'auth.email_login' }">
                        {{ $t('views.LoginView.sign_in')}}
                    </router-link>
                </div>
            </template>
        </RegisterForm>
    </div>
</template>
<script>
import RegisterForm from '@/components/forms/RegisterForm'
import { mapGetters } from 'vuex'

export default {
    name: 'EmailLogin',
    components: { RegisterForm },
    data: vm => ({
        loading        : false,
        validationError: false,
        isFormValid    : true,
        birthdateError : false,
        documentError  : false,
        emailError     : false,
        optInError     : false,
        useCNPJ        : false,
        // shouldShowAlerts: false,
        // passwordError: false,
    }),
    mounted() {
        // Depois de 5s os alertas somem
        // setTimeout(() => {
        //     this.shouldShowAlerts = false
        // }, 5000)

        this.$emit('hideWelcomeBanner')
        this.$emit('hideLogo')
    },
    methods: {
        changeDocument(value) {
            this.useCNPJ = value
        },
    },
    computed: {
        ...mapGetters({
            companySettings: 'company/settings',
            program_name: 'company/program_name',
        }),

        birthdateErrorText() {
            if (!this.$lodash.isNil(this.companySettings.allow_from_age) && !this.$lodash.isNil(this.companySettings.allow_to_age))
                return this.$t('views.RegisterView.birthdate_error.both', { from: this.companySettings.allow_from_age, to: this.companySettings.allow_to_age })

            if (!this.$lodash.isNil(this.companySettings.allow_from_age))
                return this.$t('views.RegisterView.birthdate_error.from', { from: this.companySettings.allow_from_age })
            
            if (!this.$lodash.isNil(this.companySettings.allow_to_age))
                return this.$t('views.RegisterView.birthdate_error.to', { to: this.companySettings.allow_to_age })

            return ''
        },
    },
}
</script>

<style lang="scss" scoped>
.register-form-container {
    max-width: 300px;
    margin: auto;
}

.hyper-link {
    text-decoration: none;
}

.title-font{
    font-size: 1.75rem;
}

</style>